<template>
  <svg
    v-if="name == 'locker'"
    :class="'ic_' + name"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :style="customStyle"
  >
    <rect
      x="7.46797"
      y="5.80586"
      width="25.0667"
      height="28.4"
      :stroke="color"
      stroke-width="1.6"
    />
    <path
      d="M20 5.96973V33.626"
      :stroke="color"
      stroke-width="1.6"
      stroke-miterlimit="5.75877"
    />
    <path
      d="M6.97266 20.0146L32.8646 19.9833"
      :stroke="color"
      stroke-width="1.6"
      stroke-miterlimit="5.75877"
    />
    <rect x="6.66602" y="33.3145" width="1.6" height="3.33333" :fill="color" />
    <rect x="31.7344" y="33.3408" width="1.6" height="3.33333" :fill="color" />
    <rect x="9.93555" y="11.6611" width="1.6" height="3" :fill="color" />
    <rect x="22.5117" y="11.6611" width="1.6" height="3" :fill="color" />
    <rect x="9.93555" y="25.6611" width="1.6" height="3" :fill="color" />
    <rect x="22.5117" y="25.6611" width="1.6" height="3" :fill="color" />
  </svg>
</template>
<script lang="js">
export default {
  props: {
    name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#394150",
    },
    strokeWidth:{
        type: String,
      default: "2",
    },
    secondColor: {
      type: String,
      default: "#E5E7EB",
    },
    sizeType:{
        type: String,
        default: '24',
    },
  customStyle:{default:{}}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  destroyed() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
