<template>
  <template v-if="name == 'star'">
    <svg
      :class="'ic_' + name"
      :style="customStyle"
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 0C6.80568 0.000583688 6.61846 0.105579 6.52563 0.28611L4.64949 3.98457L0.452561 4.57767C0.336852 4.59374 0.231061 4.64679 0.150065 4.72715C0.0521452 4.82502 -0.00181317 4.95668 4.65248e-05 5.09321C0.00190622 5.22974 0.0594318 5.35996 0.159983 5.45527L3.19652 8.33399L2.47912 12.3989C2.4623 12.4935 2.47306 12.5907 2.51018 12.6796C2.54731 12.7686 2.60931 12.8456 2.68916 12.902C2.76901 12.9584 2.86352 12.9919 2.96196 12.9987C3.0604 13.0055 3.15883 12.9854 3.24611 12.9406L7 11.0215V0Z"
        :fill="color"
      />
      <path
        d="M7 0C7.19432 0.000583688 7.38154 0.105579 7.47437 0.28611L9.35051 3.98457L13.5474 4.57767C13.6631 4.59374 13.7689 4.64679 13.8499 4.72715C13.9479 4.82502 14.0018 4.95668 14 5.09321C13.9981 5.22974 13.9406 5.35996 13.84 5.45527L10.8035 8.33399L11.5209 12.3989C11.5377 12.4935 11.5269 12.5907 11.4898 12.6796C11.4527 12.7686 11.3907 12.8456 11.3108 12.902C11.231 12.9584 11.1365 12.9919 11.038 12.9987C10.9396 13.0055 10.8412 12.9854 10.7539 12.9406L7 11.0215V0Z"
        :fill="secondColor"
      />
    </svg>
  </template>
</template>
<script lang="js">
export default {
  props: {
    name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#E5E7EB",
    },
    strokeWidth:{
        type: String,
      default: "2",
    },
    secondColor: {
      type: String,
      default: "#E5E7EB",
    },
    sizeType:{
        type: String,
        default: '24',
    },
  customStyle:{default:{}}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  destroyed() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
