<template>
  <svg
    v-if="name == 'webLink'"
    :class="'ic_' + name"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :style="customStyle"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.42767 16.3451C9.28615 16.3532 9.14355 16.3573 9 16.3573C8.85692 16.3573 8.7148 16.3533 8.57374 16.3452C7.59032 15.4127 6.59161 13.9106 5.92736 12.3354H12.0736C11.4095 13.9106 10.411 15.4126 9.42767 16.3451ZM12.6289 10.6928L5.37197 10.6928C5.22562 10.1059 5.14324 9.53294 5.14324 9C5.14324 8.46269 5.22698 7.88468 5.37559 7.29277H12.6252C12.7738 7.88468 12.8575 8.46269 12.8575 9C12.8575 9.53294 12.7752 10.1059 12.6289 10.6928ZM13.4925 12.3354C13.0681 13.6813 12.3941 14.8958 11.5324 15.9099C13.2828 15.2682 14.7215 13.9804 15.5596 12.3354H13.4925ZM16.1617 10.6928H13.8734C13.9571 10.1425 14.0007 9.57691 14.0007 9C14.0007 8.41804 13.9563 7.84763 13.8712 7.29277H16.1582C16.2884 7.84066 16.3573 8.41228 16.3573 9C16.3573 9.58255 16.2896 10.1493 16.1617 10.6928ZM4.12794 10.6928C4.04425 10.1425 4.00067 9.57691 4.00067 9C4.00067 8.41804 4.04502 7.84763 4.13015 7.29277H1.84176C1.71158 7.84066 1.64267 8.41228 1.64267 9C1.64267 9.58255 1.71037 10.1493 1.83834 10.6928H4.12794ZM2.44041 12.3354C3.27883 13.981 4.71827 15.2692 6.4695 15.9106C5.60759 14.8964 4.93334 13.6816 4.50881 12.3354H2.44041ZM8.57374 1.65481C7.59333 2.58442 6.59772 4.08021 5.93346 5.6501L12.0675 5.6501C11.4034 4.08026 10.408 2.58451 9.42767 1.65489C9.28615 1.64678 9.14355 1.64267 9 1.64267C8.85692 1.64267 8.7148 1.64675 8.57374 1.65481ZM13.488 5.6501H15.5522C14.713 4.01204 13.2777 2.72992 11.5324 2.09011C12.391 3.10055 13.0633 4.31003 13.488 5.6501ZM2.44779 5.6501H4.51338C4.93817 4.30972 5.61067 3.09998 6.4695 2.0894C4.7234 2.72897 3.28726 4.01143 2.44779 5.6501ZM9.00067 18C13.9709 17.9996 18 13.9703 18 9C18 4.02966 13.9709 0.000360265 9.00067 0H9C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18H9.00067Z"
      :fill="color"
    />
  </svg>
</template>
<script lang="js">
export default {
  props: {
    name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#83ACFF",
    },
     strokeWidth:{
        type: String,
      default: "1",
    },
    secondColor: {
      type: String,
      default: "#E5E7EB",
    },
    sizeType:{
        type: String,
        default: '24',
    },
    customStyle:{default:{}}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  destroyed() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
