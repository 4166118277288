<template>
  <svg
    v-if="name == 'publicShowerRoom'"
    :class="'ic_' + name"
    :style="customStyle"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.4258 5.83301H24.0794L34.0977 16.2197V34.1663H29.2226V18.359L24.0794 13.0856L20.9414 16.0023L14.1445 9.15982L17.4258 5.83301Z"
      :stroke="color"
      stroke-width="1.6"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.83008 22.9609L7.06231 21.7287L8.29455 22.9609L7.06231 24.1932L5.83008 22.9609ZM10.759 20.4965L9.52679 21.7287L8.29455 20.4965L9.52679 19.2642L10.759 20.4965ZM13.2235 18.032L11.9913 19.2642L10.759 18.032L11.9913 16.7998L13.2235 18.032ZM14.4557 16.7998L13.2235 15.5675L14.4557 14.3353L15.688 15.5675L14.4557 16.7998Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.3844 16.0812L5.87523 16.9525L5.00391 15.4434L6.51308 14.572L7.3844 16.0812ZM10.4028 14.3386L8.89358 15.2099L8.02226 13.7007L9.53143 12.8294L10.4028 14.3386ZM11.9119 13.4672L11.0406 11.9581L12.5498 11.0867L13.4211 12.5959L11.9119 13.4672Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.3438 16.6016L16.4724 18.1107L17.9816 18.9821L18.8529 17.4729L17.3438 16.6016ZM16.239 22.0004L17.1103 20.4912L15.6011 19.6199L14.7298 21.1291L16.239 22.0004ZM14.4963 25.0188L15.3676 23.5096L13.8585 22.6383L12.9871 24.1474L14.4963 25.0188Z"
      :fill="color"
    />
  </svg>
</template>
<script lang="js">
export default {
  props: {
    name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#394150",
    },
    strokeWidth:{
        type: String,
      default: "2",
    },
    secondColor: {
      type: String,
      default: "#E5E7EB",
    },
    sizeType:{
        type: String,
        default: '24',
    },
  customStyle:{default:{}}
  },
  watch: {
    // defaultIndex() {
    //   this.dIndex = this.defaultIndex;
    //   this.activeIndex = this.defaultIndex;
    // },
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  destroyed() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
