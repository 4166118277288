<template>
  <svg
    v-if="name == 'filter'"
    :class="'ic_' + name"
    :style="customStyle"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 12H14.5"
      :stroke="color"
      :stroke-width="strokeWidth"
      stroke-linecap="square"
    />
    <path
      d="M19.5 12H20"
      :stroke="color"
      :stroke-width="strokeWidth"
      stroke-linecap="square"
    />
    <path
      d="M4 18H5.5"
      :stroke="color"
      :stroke-width="strokeWidth"
      stroke-linecap="square"
    />
    <path
      d="M4 6H5.5"
      :stroke="color"
      :stroke-width="strokeWidth"
      stroke-linecap="square"
    />
    <path
      d="M11 18H20"
      :stroke="color"
      :stroke-width="strokeWidth"
      stroke-linecap="square"
    />
    <path
      d="M11 6H20"
      :stroke="color"
      :stroke-width="strokeWidth"
      stroke-linecap="square"
    />
    <circle cx="8" cy="6" r="2" :stroke="color" :stroke-width="strokeWidth" />
    <circle cx="17" cy="12" r="2" :stroke="color" :stroke-width="strokeWidth" />
    <circle cx="8" cy="18" r="2" :stroke="color" :stroke-width="strokeWidth" />
  </svg>
</template>
<script lang="js">
export default {
  props: {
     name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#394150",
    },
    strokeWidth:{
        type: String,
      default: "2",
    },
    sizeType:{
        type: String,
        default: '24',
    },secondColor: {
      type: String,
      default: "#E5E7EB",
    },
   customStyle:{default:{}}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  destroyed() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
