import { createPinia } from "pinia";
import * as Vue from "vue"; // eslint-disable-line no-unused-vars
import App from "./App.vue";
import axios from "axios";
import VueAxios from "vue-axios";
import router from "./router";
import Session from "./modules/Session";
import Vue3MobileDetection from "vue3-mobile-detection";
import Toast, { useToast } from "vue-toastification";
import "vue-toastification/dist/index.css";
import { createI18n } from 'vue-i18n'
import messages from './i18n'   
import { Rest, RestUrl } from "./modules/Rest";

import "./assets/css/reset.css";
import "./assets/css/common.scss";

import $ from "jquery";
const dev = process.env.VUE_APP_ENV === "development";
const app = Vue.createApp(App);



app.use(createPinia()); // Create the root store
app.use(router);
app.use(VueAxios, axios);
app.config.globalProperties.$JQ = $;
app.config.globalProperties.$session = Session;
app.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 10,
  newestOnTop: true,
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter((t) => t.type === toast.type).length !== 0) {
      return false;
    }
    return toast;
  },
  position: "top-center",
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: false,
  pauseOnHover: false,
  draggable: false,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: false,
  icon: "ic_toast",
  rtl: false,
  // this.$toast.success("등록에 성공했습니다 마음이 득근득근!");
  // this.$toast.error("등록에 실패하였습니다 다시 시도해주세요");
  // this.$toast("정보가 등록되었습니다");
});
app.config.globalProperties.$toast = useToast();
app.config.globalProperties.$popup = {
  hasActive: (popupId) => {
    if ($("[popup-id='" + popupId + "']").hasClass("active")) {
      return true;
    } else {
      return false;
    }
  },
  show: (popupId) => {
    $("[popup-id='" + popupId + "']").addClass("active");
    $("body").css("overflow", "hidden");
  },
  close: (popupId) => {
    $("[popup-id='" + popupId + "']").removeClass("active");
    $("body").css("overflow", "auto");
  },
  clear: () => {
    $(".popup_wrap").removeClass("active");
    $("body").css("overflow", "auto");
  },
};

app.config.globalProperties.$tooltip = {
  hasActive: (tooltipId) => {
    if ($("[tooltip-id='" + tooltipId + "']").hasClass("active")) {
      return true;
    } else {
      return false;
    }
  },
  show: (tooltipId) => {
    $("[tooltip-id='" + tooltipId + "']").addClass("active");
  },
  close: (tooltipId) => {
    $("[tooltip-id='" + tooltipId + "']").removeClass("active");
  },
  docuClick: (tooltipId) => {
    $(document).click(function (e) {
      if (0 == $(e.target).closest("[tooltip-id='" + tooltipId + "']").length) {
        $("[tooltip-id='" + tooltipId + "']").removeClass("active");
      }
    });
  },
};
const i18n = createI18n({
  defaultScope: 'global',
  warnHtmlInMessage: "off",
  locale: navigator.language.split('-')[0] != 'ko' && navigator.language.split('-')[0] ||'en',
  fallbackLocale: 'en',
  messages // set locale messages
})

app.axios.interceptors.request.use(
  function(config) {
      config.headers.locale = i18n.locale;
      return config;
  },
  function(err) {
      return Promise.reject(err);
  }
);

app.use(i18n)
app.use(Vue3MobileDetection);

app.mount("#app");

