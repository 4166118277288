<template>
    <svg class="'ic_' + name"
    :style="customStyle"
    width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3 12C4.39074 7.893 7.90926 5 12 5C16.0898 5 19.6083 7.893 21 12C19.6083 16.106 16.0898 19 12 19C7.90926 19 4.39074 16.106 3 12ZM14.4002 12.0005C14.4002 10.6399 13.3608 9.60055 12 9.60055C10.6401 9.60055 9.59981 10.6399 9.59981 12.0005C9.59981 13.3601 10.6401 14.3994 12 14.3994C13.3608 14.3994 14.4002 13.3601 14.4002 12.0005ZM16 12.0005C16 14.2398 14.2399 16 12 16C9.76007 16 8 14.2398 8 12.0005C8 9.76024 9.76007 8 12 8C14.2399 8 16 9.76024 16 12.0005Z" :fill='color'/>
    </svg>
</template>
<script lang="js">
export default {
  props: {
    name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#212936",
    },
    strokeWidth:{
        type: String,
      default: "2",
    },
    secondColor: {
      type: String,
      default: "#212936",
    },
    sizeType:{
        type: String,
        default: '24',
    },
  customStyle:{default:{}}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  destroyed() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
