<template>
  <svg
    v-if="name == 'authKakao'"
    :class="'ic_' + name"
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :style="customStyle"
  >
    <path
      opacity="0.9"
      d="M10 0.5C4.75171 0.5 0.5 3.65035 0.5 7.53468C0.565825 8.7736 0.987872 9.97137 1.71993 10.9968C2.45198 12.0223 3.46582 12.8359 4.65043 13.3486L3.5951 17.0398C3.58233 17.0869 3.57943 17.1358 3.58658 17.184C3.59373 17.2321 3.61079 17.2784 3.63676 17.3202C3.66274 17.362 3.69713 17.3986 3.73795 17.4277C3.77877 17.4568 3.82522 17.4779 3.87463 17.4898C3.92838 17.5018 3.98414 17.5032 4.03848 17.4939C4.09282 17.4846 4.14459 17.4648 4.19062 17.4358L8.80896 14.5172C9.19989 14.5539 9.59691 14.5751 10 14.5751C15.2463 14.5751 19.5 11.4248 19.5 7.54047C19.5 3.65614 15.2463 0.505794 10 0.505794"
      :fill="color"
    />
  </svg>
</template>
<script lang="js">
export default {
  props: {
    name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#000000",
    },
    secondColor: {
      type: String,
      default: "#E5E7EB",
    },
     strokeWidth:{
        type: String,
      default: "1",
    },
    sizeType:{
        type: String,
        default: '24',
    },
    customStyle:{default:{}}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  destroyed() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
