
<template>
  <svg :class="'ic_' + name"
  :style="customStyle"
   width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7071 5.08579V5H10.7929L11 4.79289L11.7071 4.08579L12.4142 4.79289L12.6213 5H12.7071V5.08579L19.4142 11.7929L18 13.2071L12.7071 7.91421V20H10.7071V7.91421L5.41421 13.2071L4 11.7929L10.7071 5.08579Z" :fill="color"/>
  </svg>
</template>
<script lang="js">
export default {
  props: {
    name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#ffffff",
    },
    strokeWidth:{
        type: String,
      default: "2",
    },
    secondColor: {
      type: String,
      default: "#212936",
    },
    sizeType:{
        type: String,
        default: '24',
    },
  customStyle:{default:{}}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  destroyed() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
