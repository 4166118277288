"use strict";
export default {
  imageSizeQueryRemove(imageUrl) {
    // 이미지 d 제거
    imageUrl = imageUrl
      .replace(/d=\w+/, "")
      .replace("?&", "?")
      .replace("&&", "&");
    return imageUrl;
  },
  bizNumberCheck(value) {
    value = value.toString();
    const valueMap = value
      .replace(/-/gi, "")
      .split("")
      .map((item) => {
        return parseInt(item, 10);
      });

    if (valueMap.length === 10) {
      const multiply = [1, 3, 7, 1, 3, 7, 1, 3, 5];
      let checkSum = 0;

      for (let i = 0; i < multiply.length; i += 1) {
        checkSum += multiply[i] * valueMap[i];
      }

      checkSum += parseInt(String((multiply[8] * valueMap[8]) / 10), 10);
      return Math.floor(valueMap[9]) === (10 - (checkSum % 10)) % 10;
    }

    return false;
  },
  dateNumberWithHyphens(value) {
    if (typeof value == "string") {
      const regNotNum = /[^0-9]/g;
      let date = value.replace(regNotNum, "");

      if (date === "" || date === null || date.length < 5) {
        value = date;
        return value;
      }

      if (date.length > 8) {
        date = date.substr(0, 8);
      }

      let dataFormat;
      let regPhonNum;

      // 날짜 포맷(yyyy-mm-dd) 만들기
      if (date.length <= 6) {
        dataFormat = "$1.$2";
        regPhonNum = /([0-9]{4})([0-9]+)/;
      } else if (date.length <= 8) {
        dataFormat = "$1.$2.$3";
        regPhonNum = /([0-9]{4})([0-9]{2})([0-9]+)/;
      }

      if (regPhonNum && dataFormat) {
        date = date.replace(regPhonNum, dataFormat);
      }

      const values = date.split(".").map((v) => {
        return v.replace(/\D/g, "");
      });
      if (values[1]) {
        values[1] = this.checkValue(values[1], 12);
      }
      if (values[2]) {
        values[2] = this.checkValue(values[2], 31);
      }

      value = values.join(".");

      return value;
    }
  },

  checkValue(str, max) {
    if (str.charAt(0) !== "0" || str === "00") {
      let num = Number(str);
      if (num <= 0 || num > max) num = 1;
      str =
        num > Number(max.toString().charAt(0)) && num.toString().length === 1
          ? "0" + num
          : num.toString();
    }
    return str;
  },

  allNumberWithInputHyphens(value) {
    if (!value) {
      return "";
    }

    value = value.replace(/[^0-9]/g, "");
    const result = [];

    let restNumber = "";

    // 지역번호와 나머지 번호로 나누기
    if (value.startsWith("02")) {
      // 서울 02 지역번호
      result.push(value.substring(0, 2));
      restNumber = value.substring(2);
    } else if (value.startsWith("1")) {
      // 지역 번호가 없는 경우
      // 1xxx-yyyy
      restNumber = value;
    } else {
      // 나머지 3자리 지역번호
      // 0xx-yyyy-zzzz
      result.push(value.substring(0, 3));
      restNumber = value.substring(3);
    }

    if (restNumber.length === 7) {
      // 7자리만 남았을 때는 xxx-yyyy
      result.push(restNumber.substring(0, 3));
      result.push(restNumber.substring(3));
    } else {
      result.push(restNumber.substring(0, 4));
      result.push(restNumber.substring(4));
    }

    return result.filter((val) => val).join("-");
  },
};
