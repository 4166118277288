<template>
    <svg 
    :class="'ic_' + name"
    :style="customStyle"
    width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="50" height="50" rx="25" :fill="secondColor"/>
      <path d="M32.9863 15.6172H28.6699V26.4082H28.5176L21.1289 15.6172H17.3965V34H21.7129V23.209H21.8398L29.3047 34H32.9863V15.6172Z" :fill="color"/>
    </svg>
</template>
<script lang="js">
export default {
  props: {
    name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#ffffff",
    },
    strokeWidth:{
        type: String,
      default: "2",
    },
    secondColor: {
      type: String,
      default: "#212936",
    },
    sizeType:{
        type: String,
        default: '24',
    },
  customStyle:{default:{}}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  destroyed() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
