<template>
    <svg  :class="'ic_' + name"
    :style="customStyle" 
    width="175" height="21" viewBox="0 0 175 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.1221 20.418C15.1831 20.418 19.0742 17.0166 19.8088 12.0098H14.4485C13.9043 14.1323 12.2445 15.52 10.1221 15.52C7.42831 15.52 5.44198 13.3703 5.44198 10.4316C5.44198 7.4928 7.42831 5.31593 10.1221 5.31593C12.1356 5.31593 13.7682 6.59484 14.4213 8.52681H19.7544C18.9653 3.71049 15.1015 0.417969 10.1221 0.417969C4.32638 0.417969 0 4.71729 0 10.4316C0 16.1186 4.32638 20.418 10.1221 20.418Z" :fill="color"/>
      <path d="M30.997 20.418C37.1192 20.418 41.4728 16.2547 41.4728 10.4316C41.4728 4.58123 37.1192 0.417969 30.997 0.417969C24.8748 0.417969 20.5212 4.60844 20.5212 10.4316C20.5212 16.2275 24.8748 20.418 30.997 20.418ZM30.997 15.5472C28.1127 15.5472 25.9632 13.3703 25.9632 10.4316C25.9632 7.46559 28.1127 5.28872 30.997 5.28872C33.9084 5.28872 36.0036 7.43838 36.0036 10.4316C36.0036 13.3976 33.9084 15.5472 30.997 15.5472Z" :fill="color"/>
      <path d="M48.6571 15.1391V0.880553H43.3239V19.9282H55.4324V15.1391H48.6571Z" :fill="color"/>
      <path d="M62.2961 15.1391V0.880553H56.9629V19.9282H69.0713V15.1391H62.2961Z" :fill="color"/>
      <path d="M75.8262 15.384V12.5268H82.0845V8.22749H75.8262V5.42477H82.7919V0.880553H70.6019V19.9282H83.0368V15.384H75.8262Z" :fill="color"/>
      <path d="M94.1008 20.418C99.1619 20.418 103.053 17.0166 103.788 12.0098H98.4272C97.883 14.1323 96.2232 15.52 94.1008 15.52C91.4071 15.52 89.4207 13.3703 89.4207 10.4316C89.4207 7.4928 91.4071 5.31593 94.1008 5.31593C96.1144 5.31593 97.747 6.59484 98.4 8.52681H103.733C102.944 3.71049 99.0803 0.417969 94.1008 0.417969C88.3051 0.417969 83.9787 4.71729 83.9787 10.4316C83.9787 16.1186 88.3051 20.418 94.1008 20.418Z" :fill="color"/>
      <path d="M119.419 0.880553H103.801V5.61525H108.971V19.9282H114.249V5.61525H119.419V0.880553Z" :fill="color"/>
      <path d="M129.97 20.418C136.092 20.418 140.446 16.2547 140.446 10.4316C140.446 4.58123 136.092 0.417969 129.97 0.417969C123.848 0.417969 119.494 4.60844 119.494 10.4316C119.494 16.2275 123.848 20.418 129.97 20.418ZM129.97 15.5472C127.086 15.5472 124.936 13.3703 124.936 10.4316C124.936 7.46559 127.086 5.28872 129.97 5.28872C132.881 5.28872 134.977 7.43838 134.977 10.4316C134.977 13.3976 132.881 15.5472 129.97 15.5472Z" :fill="color"/>
      <path d="M152.555 19.9282H158.405L154.623 13.0166C156.5 11.901 157.643 9.96899 157.643 7.62885C157.643 3.65606 154.65 0.880553 150.569 0.880553H142.297V19.9282H147.548V14.105H149.453L152.555 19.9282ZM147.548 5.56083H149.943C151.412 5.56083 152.256 6.43157 152.256 7.62885C152.256 8.79892 151.412 9.69688 149.943 9.69688H147.548V5.56083Z" :fill="color"/>
      <path d="M166.563 20.418C170.999 20.418 174.182 18.2411 174.182 14.0778C174.182 9.7513 170.536 8.79892 167.706 8.09144C165.938 7.62885 164.495 7.27511 164.495 6.18668C164.495 5.28872 165.393 4.88055 166.319 4.88055C167.407 4.88055 168.305 5.45198 168.305 6.67647H173.584C173.584 2.78531 170.944 0.445179 166.373 0.445179C161.883 0.445179 159.135 2.59484 159.135 6.34994C159.135 10.6765 162.645 11.52 165.393 12.2003C167.216 12.6357 168.713 12.9894 168.713 14.1867C168.713 15.2207 167.652 15.8193 166.563 15.8193C165.366 15.8193 164.305 15.1391 164.305 13.7785H158.89C158.89 17.9418 161.911 20.418 166.563 20.418Z" :fill="color"/>
    </svg>
</template>
<script lang="js">
export default {
  props: {
    name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#000000",
    },
    strokeWidth:{
        type: String,
      default: "2",
    },
    secondColor: {
      type: String,
      default: "#E5E7EB",
    },
    sizeType:{
        type: String,
        default: '24',
    },
  customStyle:{default:{}}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  destroyed() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
