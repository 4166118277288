<template>
  <svg  :class="'ic_' + name"
  :style="customStyle" 
  width="187" height="21" viewBox="0 0 187 21" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M179.665 12.5128C179.974 11.6776 180.495 11.0301 181.305 10.5234L181.787 11.2943C181.143 11.6967 180.755 12.1856 180.518 12.8277C180.274 13.4893 180.183 14.3331 180.183 15.4543C180.183 16.5462 180.358 17.5094 180.65 18.2401C180.947 18.9835 181.337 19.4204 181.721 19.5802L181.371 20.4194C180.664 20.1247 180.145 19.4252 179.806 18.5777C179.462 17.7175 179.273 16.6352 179.273 15.4543C179.273 14.3028 179.364 13.3285 179.665 12.5128Z" :fill="secondColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M184.335 12.5128C184.026 11.6776 183.505 11.0301 182.695 10.5234L182.213 11.2943C182.857 11.6967 183.245 12.1856 183.482 12.8277C183.726 13.4893 183.817 14.3331 183.817 15.4543C183.817 16.5462 183.642 17.5094 183.35 18.2401C183.053 18.9835 182.663 19.4204 182.279 19.5802L182.629 20.4194C183.336 20.1247 183.855 19.4252 184.194 18.5777C184.538 17.7175 184.727 16.6352 184.727 15.4543C184.727 14.3028 184.636 13.3285 184.335 12.5128Z" :fill="secondColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M184.335 18.3954C184.026 19.2306 183.505 19.8781 182.695 20.3848L182.213 19.6139C182.857 19.2115 183.245 18.7226 183.482 18.0805C183.726 17.4189 183.817 16.5751 183.817 15.4539C183.817 14.362 183.642 13.3988 183.35 12.6681C183.053 11.9247 182.663 11.4878 182.279 11.328L182.629 10.4888C183.336 10.7835 183.855 11.483 184.194 12.3305C184.538 13.1908 184.727 14.273 184.727 15.4539C184.727 16.6054 184.636 17.5797 184.335 18.3954Z" :fill="secondColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M181.999 14.0905C181.999 14.0905 181.999 14.0905 181.999 13.636L181.999 14.0905C180.802 14.0905 179.84 13.8509 179.173 13.6086C178.84 13.4874 178.58 13.3654 178.401 13.2719C178.312 13.2251 178.242 13.1854 178.193 13.1564C178.169 13.1419 178.15 13.1301 178.136 13.1214L178.119 13.1107L178.114 13.1073L178.112 13.106L178.111 13.1055C178.111 13.1054 178.111 13.1051 178.363 12.7269C178.615 12.3487 178.615 12.3486 178.615 12.3485L178.621 12.3528C178.629 12.3574 178.641 12.3651 178.659 12.3754C178.693 12.3962 178.748 12.4275 178.822 12.4659C178.969 12.5429 179.192 12.6481 179.484 12.7542C180.068 12.9664 180.924 13.1814 181.999 13.1814M181.999 13.1814L182.001 13.1814L182.013 13.1813C182.024 13.1811 182.042 13.1807 182.066 13.1799C182.114 13.1783 182.186 13.175 182.279 13.1684C182.466 13.155 182.736 13.1281 183.061 13.074C183.713 12.9653 184.575 12.7488 185.432 12.3203L185.839 13.1334C184.878 13.614 183.922 13.8521 183.21 13.9707C182.853 14.0302 182.555 14.0601 182.344 14.0751C182.239 14.0827 182.155 14.0865 182.096 14.0885C182.067 14.0895 182.044 14.09 182.027 14.0902L182.008 14.0905L182.002 14.0905L182 14.0905L181.999 14.0905" :fill="secondColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M181.999 16.8177C181.999 16.8177 181.999 16.8177 181.999 17.2722L181.999 16.8177C180.802 16.8177 179.84 17.0573 179.173 17.2996C178.84 17.4208 178.58 17.5428 178.401 17.6363C178.312 17.6831 178.242 17.7228 178.193 17.7518C178.169 17.7663 178.15 17.7781 178.136 17.7868L178.119 17.7975L178.114 17.801L178.112 17.8022L178.111 17.8027C178.111 17.8028 178.111 17.8031 178.363 18.1813C178.615 18.5595 178.615 18.5596 178.615 18.5597L178.621 18.5554C178.629 18.5508 178.641 18.5431 178.659 18.5328C178.693 18.5121 178.748 18.4807 178.822 18.4423C178.969 18.3653 179.192 18.2601 179.484 18.154C180.068 17.9418 180.924 17.7268 181.999 17.7268M181.999 17.7268L182.001 17.7268L182.013 17.727C182.024 17.7271 182.042 17.7275 182.066 17.7283C182.114 17.7299 182.186 17.7332 182.279 17.7398C182.466 17.7532 182.736 17.7801 183.061 17.8342C183.713 17.9429 184.575 18.1594 185.432 18.5879L185.839 17.7748C184.878 17.2942 183.922 17.0561 183.21 16.9375C182.853 16.878 182.555 16.8481 182.344 16.8331C182.239 16.8255 182.155 16.8217 182.096 16.8197C182.067 16.8187 182.044 16.8182 182.027 16.818L182.008 16.8177L182.002 16.8177L182 16.8177L181.999 16.8177" :fill="secondColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M181.546 19.9991V10.9082H182.455V19.9991H181.546Z" :fill="secondColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M186.09 15.9091H177.908V15H186.09V15.9091Z" :fill="secondColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M182 19.5455C184.222 19.5455 186.091 17.6506 186.091 15.2273C186.091 12.8039 184.222 10.9091 182 10.9091C179.778 10.9091 177.909 12.8039 177.909 15.2273C177.909 17.6506 179.778 19.5455 182 19.5455ZM182 20.4545C184.761 20.4545 187 18.1142 187 15.2273C187 12.3403 184.761 10 182 10C179.239 10 177 12.3403 177 15.2273C177 18.1142 179.239 20.4545 182 20.4545Z" :fill="secondColor"/>
  <path d="M10.1221 20.418C15.1831 20.418 19.0742 17.0166 19.8088 12.0098H14.4485C13.9043 14.1323 12.2445 15.52 10.1221 15.52C7.42831 15.52 5.44198 13.3703 5.44198 10.4316C5.44198 7.4928 7.42831 5.31593 10.1221 5.31593C12.1356 5.31593 13.7682 6.59484 14.4213 8.52681H19.7544C18.9653 3.71049 15.1015 0.417969 10.1221 0.417969C4.32638 0.417969 0 4.71729 0 10.4316C0 16.1186 4.32638 20.418 10.1221 20.418Z" :fill="color"/>
  <path d="M30.997 20.418C37.1192 20.418 41.4728 16.2547 41.4728 10.4316C41.4728 4.58123 37.1192 0.417969 30.997 0.417969C24.8748 0.417969 20.5212 4.60844 20.5212 10.4316C20.5212 16.2275 24.8748 20.418 30.997 20.418ZM30.997 15.5472C28.1127 15.5472 25.9632 13.3703 25.9632 10.4316C25.9632 7.46559 28.1127 5.28872 30.997 5.28872C33.9084 5.28872 36.0036 7.43838 36.0036 10.4316C36.0036 13.3976 33.9084 15.5472 30.997 15.5472Z" :fill="color"/>
  <path d="M48.6571 15.1391V0.880553H43.3239V19.9282H55.4324V15.1391H48.6571Z" :fill="color"/>
  <path d="M62.2961 15.1391V0.880553H56.9629V19.9282H69.0713V15.1391H62.2961Z" :fill="color"/>
  <path d="M75.8262 15.384V12.5268H82.0845V8.22749H75.8262V5.42477H82.7919V0.880553H70.6019V19.9282H83.0368V15.384H75.8262Z" :fill="color"/>
  <path d="M94.1008 20.418C99.1619 20.418 103.053 17.0166 103.788 12.0098H98.4272C97.883 14.1323 96.2232 15.52 94.1008 15.52C91.4071 15.52 89.4207 13.3703 89.4207 10.4316C89.4207 7.4928 91.4071 5.31593 94.1008 5.31593C96.1144 5.31593 97.747 6.59484 98.4 8.52681H103.733C102.944 3.71049 99.0803 0.417969 94.1008 0.417969C88.3051 0.417969 83.9787 4.71729 83.9787 10.4316C83.9787 16.1186 88.3051 20.418 94.1008 20.418Z" :fill="color"/>
  <path d="M119.419 0.880553H103.801V5.61525H108.971V19.9282H114.249V5.61525H119.419V0.880553Z" :fill="color"/>
  <path d="M129.97 20.418C136.092 20.418 140.446 16.2547 140.446 10.4316C140.446 4.58123 136.092 0.417969 129.97 0.417969C123.848 0.417969 119.494 4.60844 119.494 10.4316C119.494 16.2275 123.848 20.418 129.97 20.418ZM129.97 15.5472C127.086 15.5472 124.936 13.3703 124.936 10.4316C124.936 7.46559 127.086 5.28872 129.97 5.28872C132.881 5.28872 134.977 7.43838 134.977 10.4316C134.977 13.3976 132.881 15.5472 129.97 15.5472Z" :fill="color"/>
  <path d="M152.555 19.9282H158.405L154.623 13.0166C156.5 11.901 157.643 9.96899 157.643 7.62885C157.643 3.65606 154.65 0.880553 150.569 0.880553H142.297V19.9282H147.548V14.105H149.453L152.555 19.9282ZM147.548 5.56083H149.943C151.412 5.56083 152.256 6.43157 152.256 7.62885C152.256 8.79892 151.412 9.69688 149.943 9.69688H147.548V5.56083Z" :fill="color"/>
  <path d="M166.563 20.418C170.999 20.418 174.182 18.2411 174.182 14.0778C174.182 9.7513 170.536 8.79892 167.706 8.09144C165.938 7.62885 164.495 7.27511 164.495 6.18668C164.495 5.28872 165.393 4.88055 166.319 4.88055C167.407 4.88055 168.305 5.45198 168.305 6.67647H173.584C173.584 2.78531 170.944 0.445179 166.373 0.445179C161.883 0.445179 159.135 2.59484 159.135 6.34994C159.135 10.6765 162.645 11.52 165.393 12.2003C167.216 12.6357 168.713 12.9894 168.713 14.1867C168.713 15.2207 167.652 15.8193 166.563 15.8193C165.366 15.8193 164.305 15.1391 164.305 13.7785H158.89C158.89 17.9418 161.911 20.418 166.563 20.418Z" :fill="color"/>
  </svg>
</template>
<script lang="js">
export default {
props: {
  name: {
    type: String,
    default:null,
  },
  color: {
    type: String,
    default: "#000000",
  },
  strokeWidth:{
      type: String,
    default: "2",
  },
  secondColor: {
    type: String,
    default: "#393939",
  },
  sizeType:{
      type: String,
      default: '24',
  },
customStyle:{default:{}}
},
watch: {
},
data() {
  return {
    _root: this,
  };
},
methods: {},
destroyed() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
