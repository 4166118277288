<template>
  <svg :class="'ic_' + name"
  :style="customStyle"
  width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M14.7496 3V5.02881C15.2823 5.1814 15.7898 5.3934 16.2647 5.65718L17.6994 4.2224L20.5279 7.05083L19.0925 8.48622C19.3595 8.96738 19.5734 9.48212 19.7262 10.0225H21.75V14.0225H19.7127C19.5608 14.5463 19.3514 15.0457 19.0917 15.5133L20.5279 16.9494L17.6994 19.7779L16.2633 18.3417C15.7889 18.6051 15.2818 18.8169 14.7496 18.9693V21H10.7496V18.9697C10.2176 18.8173 9.71059 18.6057 9.23622 18.3425L7.80083 19.7779L4.9724 16.9494L6.40718 15.5147C6.14718 15.0466 5.93748 14.5468 5.7854 14.0225H3.75V10.0225H5.77197C5.9249 9.4816 6.13903 8.96639 6.40641 8.48484L4.9724 7.05083L7.80083 4.2224L9.23484 5.65641C9.70961 5.3928 10.2171 5.18095 10.7496 5.02847V3H14.7496ZM12.7491 8.74927C10.9542 8.74927 9.49927 10.2042 9.49927 11.9991C9.49927 13.7939 10.9542 15.2489 12.7491 15.2489C14.5439 15.2489 15.9989 13.7939 15.9989 11.9991C15.9989 10.2042 14.5439 8.74927 12.7491 8.74927Z" :fill='color'/>
  </svg>
</template>
<script lang="js">
export default {
props: {
  name: {
    type: String,
    default:null,
  },
  color: {
    type: String,
    default: "#ffffff",
  },
  strokeWidth:{
      type: String,
    default: "2",
  },
  secondColor: {
    type: String,
    default: "#212936",
  },
  sizeType:{
      type: String,
      default: '24',
  },
customStyle:{default:{}}
},
watch: {
},
data() {
  return {
    _root: this,
  };
},
methods: {},
destroyed() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
