<template>
    <div :popup-id="'popup_nft_complete'" class="popup_wrap full bottom_mobile popup_nft_complete">
        <!---no_bg,bottom,active,full,out_scroll,-->
        <div class="popup_container">
            <div class="popup_content">
                <div class="popup_header ">
                    <button v-if="getPopupData.isDetail == false" type="button" class="btn_common btn_hover btn_head_right" @click="onClose()">
                <span></span>
              </button>
                </div>
                <div class="popup_body d_f j_c_c a_i_c f_d_c">
                    <b class="f_s_30 f_w_900 t_a_c" style="padding-bottom:190px">
                        <i18n-t scope="global" v-if="getPopupData.type == 'S'" keypath="t_56">
                            <template v-slot:br>
                                <br />
                            </template>
                        </i18n-t>
                        <i18n-t scope="global" v-else keypath="t_55">
                            <template v-slot:br>
                                <br />
                            </template>
                        </i18n-t>
                    </b>
                    <p class="f_s_16">
                        <i18n-t scope="global" keypath="t_50">
                        <template v-slot:br>
                            <br />
                        </template>
                        </i18n-t>
                    </p>
                    <div class="lottie_complete">
                        <Vue3Lottie :animationData="purchaseComplete" :height="300" :width="300" />
                    </div>
                </div>
                <div class="popup_footer ">
                    <button type="button" class="btn_common btn_large btn_sub_point btn_hover" @click="onPress()">
                        {{ $t("t_52") }}
                    </button>
                    <button v-if="getPopupData.isDetail == false" type="button" class="btn_common btn_large btn_point btn_hover" @click="onClose()">
                        {{ $t("t_53") }} 
                    </button>
                </div>
            </div>
            <div class="popup_bg move_2s"></div>
        </div>
    </div>
</template>

<script>
import { Vue3Lottie } from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
import purchaseComplete from '@/assets/lottie/purchaseComplete.json'
import { mapState, mapActions } from "pinia";
import { useStore } from "../../stores/useStore";
export default {
    name: "popup_nft_complete",
    components: {
        Vue3Lottie
    },
    data() {
        return {
            _root: this,
            purchaseComplete: purchaseComplete,
            report: null,
            text: "",
        };
    },
    async created() {},
    computed: {
        ...mapState(useStore, [
            "getUser",
            "getIsLoading",
            "getMobile",
            "getWalletToken",
            "getTransferToken",
            "getTransferComplete",
            "getPopupData"
        ]),
    },
    methods: {
        ...mapActions(useStore, [
            "setUser",
            "setIsLoading",
            "setMobile",
            "logout",
            "hasUser",
            "setWalletToken",
            "setTransferToken",
            "setTransferComplete",
            "setPopupData"
        ]),
        onPress() {
            this.$popup.close('popup_nft_complete');
            this.$router.push({path:'/wallet'})
        },
        onClose() {
            var self=this;
            this.$popup.close('popup_nft_complete');
            if(this.getPopupData.callback){
                this.getPopupData.callback();
            }
            setTimeout(function() {
                self.setPopupData({isVisible:false,type:'',isDetail:false,callback:null},)
            }, 500);
        },
    },
};
</script>

<style scoped>
.popup_nft_complete.active .lottie_complete{
    display: none;
    position: absolute;z-index: -1;
}
.popup_nft_complete.active .lottie_complete{
    display: block;
}
</style>
