<template>
  <svg :class="'ic_' + name"
  :style="customStyle"
  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M19 4H13H5V8V16V20H13H19V16H13V8H19V4ZM21 10H15V14H21V10Z"  :fill='color'/>
  </svg>
</template>
<script lang="js">
export default {
props: {
  name: {
    type: String,
    default:null,
  },
  color: {
    type: String,
    default: "#ffffff",
  },
  strokeWidth:{
      type: String,
    default: "2",
  },
  secondColor: {
    type: String,
    default: "#212936",
  },
  sizeType:{
      type: String,
      default: '24',
  },
customStyle:{default:{}}
},
watch: {
},
data() {
  return {
    _root: this,
  };
},
methods: {},
destroyed() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
