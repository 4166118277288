// index.js

// import ko from '@/locales/ko.json'
import en from '@/locales/en.json'
import ja from '@/locales/ja.json'
import zh from '@/locales/zh.json'
import vi from '@/locales/vi.json'
import th from '@/locales/th.json'

export default {
  // 'ko': ko,
  'en': en,
  'ja': ja, 
  'zh': zh,
  'vi': vi,
  'th': th,
}