import VueRouter, { createRouter, createWebHistory } from "vue-router";
import Session from "../modules/Session";
import { Common } from "../modules/Common";
import { useStore } from "../stores/useStore";


const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
  routes: [
    {
      path: "/",
      redirect: "/home",
      component: () => import("../layout/mainLayout"),
      children: [
        {
          path: "/:pathMatch(.*)*",
          name: "NotFound",
          component: () => import("../views/error_404"),
        },
        {
          path: "/home",
          name: "home",
          component: () => import("../views/home"),
        },
      ],
    },
  ],
});
export default router;
