<template>
  <svg
    v-if="name == 'call'"
    :class="'ic_' + name"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :style="customStyle"
  >
    <path
      d="M13.9719 11.0573C13.8321 10.9084 13.3384 10.468 12.4309 9.88542C11.5167 9.29773 10.8424 8.9313 10.649 8.84585C10.632 8.83829 10.6132 8.83552 10.5947 8.83783C10.5762 8.84014 10.5587 8.84745 10.544 8.85898C10.2325 9.10193 9.70804 9.54818 9.67992 9.57229C9.4983 9.72792 9.4983 9.72792 9.34964 9.67944C9.0882 9.59372 8.27604 9.16246 7.56834 8.45316C6.86065 7.74387 6.40715 6.91162 6.32143 6.65045C6.27241 6.50152 6.27241 6.50152 6.42858 6.31991C6.45269 6.29178 6.89922 5.76731 7.14217 5.45605C7.15369 5.4414 7.161 5.42388 7.16332 5.40538C7.16563 5.38687 7.16286 5.36809 7.1553 5.35105C7.06985 5.15739 6.70341 4.48345 6.11571 3.56923C5.53231 2.66198 5.09247 2.16831 4.94354 2.02849C4.92987 2.01559 4.91298 2.00662 4.89464 2.00252C4.8763 1.99842 4.8572 1.99934 4.83934 2.00518C4.31874 2.18409 3.81644 2.41236 3.3393 2.68689C2.87869 2.95466 2.44261 3.26255 2.03614 3.607C2.02195 3.61906 2.01131 3.63476 2.00537 3.65241C1.99942 3.67006 1.99839 3.68899 2.00239 3.70718C2.05837 3.96808 2.32597 5.05721 3.15635 6.56581C4.0036 8.10548 4.59076 8.89433 5.83499 10.1343C7.07922 11.3742 7.89299 11.9967 9.43428 12.844C10.9429 13.6743 12.0326 13.9422 12.2929 13.9976C12.3112 14.0016 12.3301 14.0006 12.3478 13.9946C12.3655 13.9887 12.3812 13.9781 12.3934 13.9639C12.7378 13.5574 13.0456 13.1214 13.3132 12.6607C13.5877 12.1836 13.816 11.6813 13.9949 11.1607C14.0007 11.143 14.0015 11.1241 13.9975 11.1059C13.9934 11.0877 13.9846 11.0709 13.9719 11.0573Z"
      :fill="color"
    />
  </svg>
</template>
<script lang="js">
export default {
  props: {
    name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#E5E5E5",
    },
     strokeWidth:{
        type: String,
      default: "1",
    },secondColor: {
      type: String,
      default: "#E5E7EB",
    },
    sizeType:{
        type: String,
        default: '24',
    },
    customStyle:{default:{}}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  destroyed() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
