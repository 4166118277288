<template>
  <svg
    v-if="name == 'parking'"
    :class="'ic_' + name"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :style="customStyle"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.6686 14.1667H21.8346C23.6756 14.1667 25.168 15.6591 25.168 17.5C25.168 19.3409 23.6756 20.8333 21.8346 20.8333H17.6686V14.1667ZM17.668 12.5003V12.5H21.8346C24.5961 12.5 26.8346 14.7386 26.8346 17.5C26.8346 20.2614 24.5961 22.5 21.8346 22.5H17.6686V27.5003H16.002V12.5003H17.668Z"
      :fill="color"
    />
    <circle
      cx="20"
      cy="20"
      r="17.5"
      :stroke="color"
      :stroke-width="strokeWidth"
    />
  </svg>
</template>
<script lang="js">
export default {
  props: {
    name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#394150",
    },
     strokeWidth:{
        type: String,
      default: "1.6",
    },secondColor: {
      type: String,
      default: "#E5E7EB",
    },
    sizeType:{
        type: String,
        default: '24',
    },
    customStyle:{ default: {},}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  destroyed() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
