<template>
  <svg
    v-if="name == 'camera'"
    :class="'ic_' + name"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :style="customStyle"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.33342 13.3044L1.33337 4.38283H5.1429L6.03617 2.69501H9.97368L10.8572 4.38283H14.6667L14.6668 13.3044H1.33342ZM8.00004 6.99872C7.07957 6.99872 6.33338 7.74491 6.33338 8.66539C6.33338 9.58586 7.07957 10.3321 8.00004 10.3321C8.92052 10.3321 9.66671 9.58586 9.66671 8.66539C9.66671 7.74491 8.92052 6.99872 8.00004 6.99872ZM5.00004 8.66539C5.00004 7.00853 6.34319 5.66539 8.00004 5.66539C9.6569 5.66539 11 7.00853 11 8.66539C11 10.3222 9.6569 11.6654 8.00004 11.6654C6.34319 11.6654 5.00004 10.3222 5.00004 8.66539ZM12.8334 5.99872H11.5V7.33205H12.8334V5.99872Z"
      :fill="color"
    />
  </svg>
</template>
<script lang="js">
export default {
  props: {
    name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#394150",
    },
     strokeWidth:{
        type: String,
      default: "1",
    },secondColor: {
      type: String,
      default: "#E5E7EB",
    },
    sizeType:{
        type: String,
        default: '24',
    },
    customStyle:{default:{}}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  destroyed() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
