<template>
  <svg
    v-if="name == 'footerKakao'"
    :class="'ic_' + name"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :style="customStyle"
  >
    <path
      d="M9.99945 0.000976562C4.47751 0.000976562 0 4.11346 0 9.18732C0 12.9339 1.57991 14.7226 3.74398 16.3531H3.75505V20.748C3.755 20.7948 3.76805 20.8408 3.79276 20.8807C3.81747 20.9206 3.85286 20.9528 3.89497 20.9739C3.93709 20.995 3.98428 21.0041 4.03127 21.0001C4.07826 20.996 4.12321 20.9792 4.16109 20.9513L7.95265 18.1528L8.03452 18.188C8.68267 18.3066 9.34038 18.3662 9.99945 18.366C15.5225 18.366 20 14.2535 20 9.18073C20 4.10797 15.5225 0.000976562 9.99945 0.000976562ZM6.64823 11.5188C7.62848 11.5188 8.46711 10.9365 8.71826 10.0366H10.1687C9.81358 11.7693 8.45605 12.9043 6.64823 12.9043C4.54611 12.9043 2.8799 11.3474 2.8799 9.18073C2.8799 7.01407 4.54611 5.45829 6.64823 5.45829C8.47486 5.45829 9.84124 6.61633 10.1798 8.37867H8.73375C8.51248 7.42059 7.64618 6.82289 6.64823 6.82289C5.23428 6.82289 4.29496 7.82382 4.29496 9.18073C4.2908 9.48882 4.34882 9.79462 4.46559 10.0801C4.58237 10.3655 4.75553 10.6249 4.97487 10.8428C5.19421 11.0607 5.45529 11.2328 5.74268 11.3489C6.03008 11.465 6.33799 11.5228 6.64823 11.5188ZM16.2018 12.7614H14.8742V9.78502C14.8742 9.09943 14.4725 8.73136 13.8541 8.73136C13.1637 8.73136 12.7211 9.15107 12.7211 9.99927V12.7614H11.3935V5.48136H12.7211V8.21936C13.0409 7.73922 13.5553 7.5041 14.2557 7.5041C14.5148 7.49411 14.7731 7.53912 15.0133 7.63612C15.2535 7.73313 15.4701 7.8799 15.6486 8.06664C16.0193 8.44459 16.2018 8.95659 16.2018 9.62132V12.7614Z"
      :fill="color"
    />
  </svg>
</template>
<script lang="js">
export default {
  props: {
    name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#4D5562",
    },
     strokeWidth:{
        type: String,
      default: "1",
    },secondColor: {
      type: String,
      default: "#E5E7EB",
    },
    sizeType:{
        type: String,
        default: '24',
    },
    customStyle:{default:{}}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  destroyed() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
