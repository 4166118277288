"use strict";
import Axios from "axios";
import Session from "./Session";
import base64decode from 'base-64';
import CryptoJS from 'crypto-js';
import Toast, { useToast } from "vue-toastification";
/**
 * 오류 코드
 */
const ErrorCode = {};

const RestUrl = {
  VUE_APP_API: process.env.VUE_APP_API,
};

const getConfig = () => {
 
  let authHeader = null;
  var payload = Session.getAuth(); /// Add header
  // console.log('payload',payload)
  var lang = Session.getLang();

  // console.log('load payload :: ' + JSON.stringify(payload));
  if (payload != null && payload != undefined){
    authHeader = generateAccessToken(payload);
    // console.log('authHeader',authHeader)
  }else{
    // console.log('not found token ... ');
  }

  const config = {
    headers: {'Access-Token': authHeader, 'lang-code': lang},
  };

  return config;
};
const onSuccess = (response) => {
  if (response.headers.authorization) {
    Session.setAuth(response.headers.authorization)
  }
  console.log('Request Successful!', response.data);
  return response.data;
};

const onError = (error)=> {
  if (error.response) {
    // The request was made, but the server responded with a status code
    // console.log('error.data', error.response.data);
    // console.log('error.status', error.response.status);
    // console.log(error.response.headers);
  } else if (error.request) {
    // 요청이 전송되었지만, 응답이 수신되지 않았습니다.
    // 'error.request'는 브라우저에서 XMLHtpRequest 인스턴스이고,
    // node.js에서는 http.ClientRequest 인스턴스입니다.
    // console.log(error.request);
  } else {
    // 오류가 발생한 요청을 설정하는 동안 문제가 발생했습니다.
    // console.log('Error', error.message);
  }
  console.log('Request Error!',error.response.data);

  return error.response.data;
};


const Rest = {
  PAGE_LIMIT: 20,
  async axios(type = 'get', url, body = null) {
    let response
    try {
      if(type == 'post'){
        response = await Axios.post(url, body, getConfig())
        if (response.headers.authorization) {
          Session.setAuth(response.headers.authorization)
        }
        return response
      }else if(type == 'upload'){
        response = await Axios.post(url, body, {
          headers: { 'content-type': 'multipart/form-data' },
        })
        return response
      }else{
        response = await Axios[type](url, getConfig())
        if (response.headers.authorization) {
          Session.setAuth(response.headers.authorization)
        }
        return response
      }
     
    } catch (e) {
      return e.response;
    }
  },
 
};


const secretKey = 'KRYreYfo73Ga8s77';

const getTokenHeader = () => {
  return {
    alg: 'HS256',
    typ: 'JWT',
    timestamp: new Date().getTime(),
  };
}

const generateAccessToken = (payload) => {
  var bHeader = base64decode.encode(JSON.stringify(getTokenHeader()));
  var bPayload = base64decode.encode(payload);

  var hash = CryptoJS.HmacSHA256(bHeader + '.' + bPayload, secretKey);
  var signature = CryptoJS.enc.Base64.stringify(hash);

  var t = bHeader + '.' + bPayload + '.' + signature;

  //   console.log('gen token :: ' + t);

  return t;
}

export { RestUrl, Rest, ErrorCode, getConfig, generateAccessToken };
