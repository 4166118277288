<template>
  <svg
    v-if="name == 'badge'"
    :class="'ic_' + name"
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.427 14.8844C28.4262 13.8693 28.0946 12.8821 27.4825 12.0731C26.8705 11.264 26.0115 10.6773 25.0362 10.4021C25.4764 9.53149 25.6327 8.5444 25.483 7.58004C25.3333 6.61569 24.8852 5.72275 24.202 5.02716C23.5187 4.33157 22.6347 3.86843 21.6746 3.70308C20.7146 3.53773 19.727 3.6785 18.8511 4.10554C18.5451 3.17221 17.9525 2.35961 17.1581 1.78382C16.3636 1.20803 15.4078 0.898493 14.4273 0.899416C13.4467 0.900339 12.4915 1.21168 11.6981 1.78896C10.9047 2.36625 10.3137 3.17995 10.0095 4.11386C9.13347 3.68276 8.14431 3.53863 7.18202 3.70187C6.21973 3.86512 5.33306 4.32747 4.64754 5.02348C3.96202 5.71949 3.51238 6.61389 3.36226 7.58007C3.21215 8.54625 3.36916 9.53526 3.81108 10.4071C2.83722 10.683 1.97976 11.2696 1.36874 12.078C0.757727 12.8863 0.426493 13.8721 0.425293 14.8861C0.425293 14.8861 0.425293 14.8961 0.425293 14.9011C0.425293 14.9061 0.425293 14.9111 0.425293 14.9161C0.426438 15.9309 0.758219 16.9177 1.37022 17.7264C1.98223 18.5351 2.84101 19.1216 3.81607 19.3967C3.37588 20.2673 3.21959 21.2544 3.36927 22.2188C3.51895 23.1831 3.96702 24.0761 4.6503 24.7717C5.33358 25.4673 6.21757 25.9304 7.17761 26.0957C8.13764 26.2611 9.12527 26.1203 10.0012 25.6933C10.3072 26.6266 10.8997 27.4392 11.6942 28.015C12.4887 28.5908 13.4444 28.9003 14.425 28.8994C15.4055 28.8985 16.3607 28.5872 17.1541 28.0099C17.9475 27.4326 18.5385 26.6189 18.8428 25.685C19.7186 26.1157 20.7075 26.2596 21.6694 26.0963C22.6314 25.9331 23.5178 25.4709 24.2032 24.7752C24.8885 24.0795 25.3382 23.1856 25.4886 22.2197C25.639 21.2539 25.4825 20.2652 25.0412 19.3934C26.0153 19.1174 26.873 18.5305 27.484 17.7219C28.0951 16.9132 28.4261 15.927 28.427 14.9127V14.8844Z"
      fill="white"
    />
    <path
      d="M26.4189 14.8793C26.4183 14.0092 26.1341 13.1631 25.6095 12.4697C25.0849 11.7762 24.3487 11.2734 23.5127 11.0375C23.89 10.2913 24.024 9.44528 23.8957 8.61874C23.7674 7.7922 23.3834 7.02686 22.7977 6.43068C22.2121 5.83449 21.4544 5.43755 20.6316 5.29582C19.8088 5.1541 18.9623 5.27475 18.2115 5.64077C17.9493 4.84081 17.4414 4.14435 16.7605 3.65084C16.0795 3.15734 15.2604 2.89203 14.4199 2.89282C13.5795 2.89362 12.7608 3.16046 12.0808 3.65525C11.4008 4.15003 10.8942 4.84745 10.6335 5.6479C9.88266 5.2784 9.03485 5.15487 8.21008 5.29479C7.38531 5.4347 6.62535 5.83098 6.0378 6.42753C5.45025 7.02407 5.06486 7.79065 4.9362 8.61876C4.80754 9.44686 4.94211 10.2945 5.32087 11.0418C4.48619 11.2783 3.75127 11.7811 3.22757 12.4739C2.70387 13.1666 2.41997 14.0117 2.41895 14.8807C2.41895 14.8807 2.41895 14.8893 2.41895 14.8935C2.41895 14.8978 2.41895 14.9021 2.41895 14.9064C2.41993 15.7762 2.70429 16.622 3.22884 17.3151C3.75338 18.0083 4.48944 18.5109 5.32515 18.7467C4.94787 19.4929 4.81392 20.3389 4.94221 21.1655C5.07049 21.992 5.45453 22.7574 6.04016 23.3535C6.6258 23.9497 7.38346 24.3467 8.2063 24.4884C9.02914 24.6301 9.87563 24.5095 10.6264 24.1435C10.8886 24.9434 11.3965 25.6399 12.0774 26.1334C12.7584 26.6269 13.5775 26.8922 14.418 26.8914C15.2584 26.8906 16.0771 26.6238 16.7571 26.129C17.4371 25.6342 17.9437 24.9368 18.2044 24.1363C18.9551 24.5055 19.8026 24.6288 20.6271 24.4889C21.4516 24.349 22.2113 23.9529 22.7988 23.3566C23.3862 22.7603 23.7716 21.9941 23.9005 21.1663C24.0294 20.3385 23.8953 19.4911 23.517 18.7439C24.3519 18.5073 25.087 18.0043 25.6108 17.3112C26.1345 16.6181 26.4182 15.7728 26.4189 14.9035V14.8793Z"
      :fill="color"
    />
    <path
      d="M10.6615 16.0616C10.3087 16.0616 10.0693 16.2949 10.0693 16.6838V19.8597C10.0693 20.2486 10.3087 20.4819 10.6615 20.4819H18.7628C19.1282 20.4819 19.3675 20.2486 19.3675 19.8597V16.6838C19.3675 16.2949 19.1282 16.0616 18.7628 16.0616H10.6615ZM11.2158 12.5758C11.0142 12.1221 11.0646 11.811 11.0646 11.2017V9.42583H9.5527V11.2017C9.5527 11.6943 9.5275 12.174 9.35111 12.5629C9.04873 13.2629 8.70855 13.9888 8.26758 14.6628L9.30071 15.5573C9.61569 15.0517 9.95587 14.3777 10.2457 13.7554C10.2835 13.6906 10.3087 13.6129 10.3338 13.4962C10.359 13.6129 10.3968 13.6906 10.4346 13.7554C10.7244 14.2869 11.2284 14.9869 11.619 15.3888C11.9843 14.9869 12.5135 14.2869 12.8033 13.7554C12.8285 13.6906 12.8537 13.6129 12.8789 13.4962C12.9041 13.6129 12.9293 13.6906 12.9671 13.7554C13.2191 14.2869 13.5844 14.8832 13.8994 15.3628L14.8444 14.3906C14.4916 13.898 14.0002 13.0166 13.8364 12.5758C13.6852 12.1999 13.6726 11.6943 13.6726 11.2017V9.42583H12.1607V11.2017C12.1607 11.811 12.2237 12.1221 11.9969 12.5758C11.8962 12.8221 11.7576 13.0814 11.6064 13.3536C11.4552 13.0814 11.3166 12.8221 11.2158 12.5758ZM15.4366 9.30788V15.3616H16.9107V12.8856H17.8556V15.4393H19.3675V9.2301H17.8556V11.4986H16.9107V9.30788H15.4366ZM11.6442 19.069V17.4616H17.7926V19.069H11.6442Z"
      fill="white"
    />
  </svg>
</template>
<script lang="js">
export default {
  props: {
    name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#D1D5DB",
    }, strokeWidth:{
        type: String,
      default: "2",
    },
    secondColor: {
      type: String,
      default: "#E5E7EB",
    },
    sizeType:{
        type: String,
        default: '24',
    },
  customStyle:{default:{}}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  destroyed() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
