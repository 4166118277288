<template v-if="Object.keys(this.$options.components).find((t) => t === this.name)">
  <component :is="$options.components[name]" :name="name" :color="color" :strokeWidth="strokeWidth" :sizeType="sizeType" :customStyle="customStyle" :secondColor="secondColor" />
</template>

<script lang="js">
import retry from './retry'
import sportswearRental from './sportswearRental'
import towelRental from './towelRental'
import publicShowerRoom from './publicShowerRoom'
import locker from './locker'
import wifi from './wifi'
import bodyType from './bodyType'
import parking from './parking'
import privateShowerRoom from './privateShowerRoom'
import search from './search'
import plus from './plus'
import close from './close'
import badge from './badge'
import profile from './profile'
import dropDown from './dropDown'
import foldUp from './foldUp'
import filter_ from './filter'
import arrowLeft from './arrowLeft'
import arrowRight from './arrowRight'
import sortDefault from './sortDefault'
import sortUp from './sortUp'
import sortDown from './sortDown'
import star from './star'
import siren from './siren'
import camera from './camera'
import instagram from './instagram'
import webLink from './webLink'
import call from './call'
import location from './location'
import authKakao from './authKakao'
import footerKakao from './footerKakao'
import footerInsta from './footerInsta'
import markKakao from './markKakao'
import step from './step'
import hamburger from './hamburger'
import letter from './letter'
import pencilFill from './pencilFill'
// 
import arrowBack from './arrowBack'
import logo from './logo'
import logoGlobal from './logoGlobal'
import tokenNFT from './tokenNFT'
import tokenETH from './tokenETH'
import statusSend from './statusSend'
import statusReceive from './statusReceive'
import statusFee from './statusFee'
import statusFriend from './statusFriend'
import home from './home'
import market from './market'
import wallet from './wallet'
import setting from './setting'
import passwordEye from './passwordEye'
import loading from './loading'
import puff from './puff'
import rings from './rings'
import checkbox from './checkbox'
import arrowSwap from './arrowSwap'
export default {
    name: "svgIcon",
    components: {
        retry,
        sportswearRental,
        towelRental,
        publicShowerRoom,
        locker,
        wifi,
        bodyType,
        parking,
        privateShowerRoom,
        search,
        plus,
        close,
        badge,
        profile,
        dropDown,
        foldUp,
        filter_,
        arrowLeft,
        arrowRight,
        sortDefault,
        sortUp,
        sortDown,
        star,
        siren,
        camera,
        instagram,
        webLink,
        call,
        location,
        authKakao,
        footerKakao,
        footerInsta,
        markKakao,
        step,
        hamburger,
        letter,
        pencilFill,
        //
        arrowBack,
        logo,logoGlobal,
        tokenNFT,
        tokenETH,
        statusSend,statusReceive,statusFee,statusFriend,
        home,market,wallet,setting,passwordEye,loading,rings,puff,checkbox,arrowSwap
    },
    props: ['name', 'color', 'strokeWidth', 'sizeType', 'customStyle', 'secondColor'],
    watch: {
        defaultIndex() {
            this.name = this.name;
            this.color = this.color;
            this.secondColor = this.secondColor;
            this.strokeWidth = this.strokeWidth;
            this.sizeType = this.sizeType;
            this.customStyle = this.customStyle;
        },
    },
    data() {
        return {
            _root: this,
        };
    },
    methods: {},
    destroyed() {},

};
</script>

<style scoped>
@media only screen and (min-width: 280px) {}
</style>
