<template>
  <template v-if="name == 'plus'">
    <svg
      v-if="sizeType == '12'"
      :class="'ic_' + name"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 2.5V6M6 6V9.5M6 6H2.5M6 6H9.5"
        :stroke="color"
        :stroke-width="strokeWidth"
        stroke-linecap="square"
      />
    </svg>
    <svg
      v-else-if="sizeType == '16'"
      :class="'ic_' + name"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.9987 3.33301V7.99967M7.9987 7.99967V12.6663M7.9987 7.99967H3.33203M7.9987 7.99967H12.6654"
        :stroke="color"
        :stroke-width="strokeWidth"
        stroke-linecap="square"
      />
    </svg>
    <svg
      v-else
      :class="'ic_' + name"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 5V12M12 12V19M12 12H5M12 12H19"
        :stroke="color"
        :stroke-width="strokeWidth"
        stroke-linecap="square"
      />
    </svg>
  </template>
</template>
<script lang="js">
export default {
  props: {
    name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#394150",
    },
    secondColor: {
      type: String,
      default: "#E5E7EB",
    },
    strokeWidth:{
      type: String,
      default: "2",
    },
    sizeType:{
        type: String,
        default: '24',
    },
   customStyle:{ default: {},}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  destroyed() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
