<template>
  <header class="header_wrap">
    <template v-if="$route.path == '/home'">
      <h1><svgIcon :name="getEnv == 'global' ? 'logoGlobal':'logo'" /></h1>
    </template>
    <template v-else-if="$route.path =='/market' ||$route.path == '/wallet'||$route.path == '/setting'">
      <h1>{{ title }}</h1>
    </template>
    <template v-else>
      <button type="button" class="btn_common btn_icon btn_hover" @click="$router.back()">
      <svgIcon
        :name="'arrowBack'"
      />
      </button>
      <h2>{{ title }}</h2>
      <slot name="inner_right"></slot>
    </template>
    <!-- $route.path == '/home' ||  -->
    <button v-if="$route.path == '/wallet'||$route.path == '/wallet/nft'" type="button" class="btn_common btn_hover btn_medium btn_sub_point " 
    @click="()=>{
      popupActive = 'S';$popup.show('popup_multi_sell');
    }" 
    :disabled="getMyNftData.availableCount < 1"
    ><b class=" f_w_700" :class="!(getMyNftData.availableCount < 1) && 'flash_sell'">{{$t("t_46")}}</b></button>
    
  </header>
  
  
  <popupNftComplete ref="popupNftComplete" ></popupNftComplete>
</template>

<script>
import { Rest, RestUrl } from "../modules/Rest";
import { Common } from "../modules/Common";
import svgIcon from "../../src/components/svg";
import Session from "../modules/Session";
import popupNftComplete from "../components/popup/popupNftComplete.vue";
import Root from '../views/root.vue'
import { mapState, mapActions } from "pinia";
import { useStore } from "../stores/useStore";
export default {
  components: { svgIcon,popupNftComplete },
  name: "appHeader",
  props: {
    title: {
        type: String,
        default: '',
    },
  },
  watch: {
    defaultIndex() {
        this.title = this.title;
    },
  },
  data() {
    return {
      root: this,
      common: Common,
      popupActive: null,
    };
  },
  computed: {
      ...mapState(useStore, [
          "getPopupData",
          "getMyNftData",
          "getMyWallet",
          "getEnv"
      ]),
  },
  created() {
    // console.log(this.$route)
    if (Session.hasSession()) {
      this.getNftTotal()
      this.getEthBalance();
    }
  
  },
  
  methods: {
      ...mapActions(useStore, [
          "setIsLoading",
          "setPopupData",
          "setMyNftData",
          "setMyWallet"
      ]),
      async getNftTotal() {
        var err;
        var self = this;
        try {
            let url = RestUrl.VUE_APP_API + '/nft-wallet/total';
            await Rest.axios(
                    'get',
                    url
                ).then(response => {
                      if(response.status !== 200){
                        err = response.data;
                        return
                      }
                      response = response.data
                   this.setMyNftData(response)
                })
                .finally(() => {
                    setTimeout(function() {
                        if (err) {
                            self.$toast.error(err.message);
                        }
                    }, 100);
                });

        } catch (error) {
            console.log(error);
        }
      },
      async getEthBalance() {
      var err;
      var self = this;
      try {
          let url = RestUrl.VUE_APP_API + '/wallets/tokens/2/balance';
          await Rest.axios(
                  'get',
                  url
              ).then(response => {
                      if(response.status !== 200){
                        err = response.data;
                        return
                      }
                      response = response.data
                  if (Object.keys(response).length > 0) {
                    this.setMyWallet(response)
                  }
              })
              .finally(() => {
                  setTimeout(function() {
                      if (err) {
                          self.$toast.error(err.message);
                      }
                  }, 100);
              });

      } catch (error) {
          console.log(error);
      }
    },
  },
  
  destroyed() {
  },
};
</script>
<style scoped>
 @keyframes flash { 0%, to { opacity: 1; } 50% { opacity: 0.5; } }
 
 .flash_buy {
     animation: flash 2s infinite ease-in-out;
 }
 .flash_sell {
     animation: flash 1s infinite ease-in-out;
 }
</style>
