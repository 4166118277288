<script>
import { Rest, RestUrl } from "../modules/Rest";
import { Common } from "../modules/Common";
import Session from "../modules/Session";
import { mapState, mapActions } from "pinia";
import { useStore } from "../stores/useStore";
import Rules from "../modules/Rules";
import Formatter from "../modules/Formatter";
import svgIcon from "../components/svg";
import appHeader from "../layout/appHeader";
import appFooter from "../layout/appFooter";
import moment from "moment";
export default {
    components: {  svgIcon,appHeader,appFooter, },
    data() {
        return {
            _root: this,
            _common: Common,
            _rules: Rules,
            _rest: Rest,
            _restUrl: RestUrl,
            _formatter: Formatter,
            _session: Session,
            _moment:moment,
        };
    },
    computed: {
        ...mapState(useStore, [
            "getUser",
            "getIsLoading",
            "getMobile",
            "getWalletToken",
            "getTransferToken",
            "getTransferComplete",
            "getShops",
            "getPopupData",
            "getMyNftData",
            "getMyWallet",
            "getSwapComplete",
            "getEnv",
            "getLimits"
        ]),
    },
    mounted: function() {},
    created() {
       
    },
    methods: {
        ...mapActions(useStore, [
            "setUser",
            "setIsLoading",
            "setMobile",
            "logout",
            "hasUser",
            "setWalletToken",
            "setTransferToken",
            "setTransferComplete",
            "setShops",
            "setPopupData",
            "setMyNftData",
            "setMyWallet",
            "setSwapComplete",
            "getCampaign",
            "setEnv",
            "setLimits",
        ]),
     

    },
};
</script>
