<template>
    <svg 
    :class="'ic_' + name"
    :style="customStyle"
    width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path  
    :stroke="color"
    :stroke-width="strokeWidth" d="M19 12L6 12" stroke-linecap="square"/>
    <path :stroke="color"
    :stroke-width="strokeWidth" d="M11.2109 18L4.99968 12L11.2109 6" stroke-linecap="square"/>
    </svg>
</template>
<script lang="js">
export default {
  props: {
    name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#394150",
    },
    strokeWidth:{
        type: String,
      default: "2",
    },
    secondColor: {
      type: String,
      default: "#E5E7EB",
    },
    sizeType:{
        type: String,
        default: '24',
    },
  customStyle:{default:{}}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  destroyed() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
