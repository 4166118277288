"use strict";
import _formatter from "./Formatter";

const emailReg =
  /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/;
const passwordReg = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d$@$!%*#?&]{8,20}$/;
const userIdReg = /^[a-zA-Z\d!@\-_]{1,20}$/;
const contactReg =
  /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/;
const phoneNumberReg = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
const dateReg = /[0-9]{4}.(0?[1-9]|1[012]).(0?[1-9]|[12][0-9]|3[01])/;
const urlReg =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
const numberReg = /[0-9\-]+/;

export default {
  validator(name, value,root) {
    value = value.toString();

    var required = this.rulesText(name,root).required?.value == true;
    var pattern = this.rulesText(name,root).pattern?.value;
    var match = this.rulesText(name,root).match?.value;

    // console.log(name, required, same, pattern);
    //필수
    if (required && value.length <= 0) {
      return this.rulesText(name,root).required.message;
    }

    // 패턴 일반 확인
    if (pattern != undefined && !pattern.test(value)) {
      return this.rulesText(name,root).pattern.message;
    }

    // 매치 일반 확인
    if (match != undefined && !value.match(match)) {
      return this.rulesText(name,root).match.message;
    }

    return true;
  },
  rulesText(name,root) {
    var result = {
      userId: {
        placeholder: root.$t("t_61"),
        required: {
          value: true,
          message: root.$t("t_62"),
        },
        pattern: {
          value: userIdReg,
          message: root.$t("t_62"),
        },
      },
      name: {
        placeholder: root.$t("t_63"),
        required: {
          value: true,
          message: root.$t("t_64"),
        },
      },
      email: {
        placeholder:root.$t("t_65"),
        required: {
          value: true,
          message: root.$t("t_66"),
        },
        pattern: {
          value: emailReg,
          message: root.$t("t_67"),
        },
      },
      phone:{
        placeholder: root.$t("t_68"),
        required: {
          value: true,
          message: root.$t("t_69"),
        },
      },
      password: {
        placeholder: root.$t("t_70"),
        required: {
          value: true,
          message: root.$t("t_71"),
        },
        pattern: {
          value: passwordReg,
          message: root.$t("t_72"),
        },
      },
      passwordConfirm: {
        placeholder: root.$t("t_73"),
        required: {
          value: true,
          message: root.$t("t_74"),
        },
      },
      transferPassword: {
        placeholder: root.$t("t_75"),
        required: {
          value: true,
          message: root.$t("t_76"),
        },
        pattern: {
          value: passwordReg,
          message: root.$t("t_77"),
        },
      },
      transferPasswordConfirm: {
        placeholder: root.$t("t_78"),
        required: {
          value: true,
          message: root.$t("t_79"),
        },
      },
      recommendationId: {
        placeholder: root.$t("t_80"),
        required: {
          value: true,
          message: root.$t("t_170"),
        },
      },
      //   "": { placeholder: "", error: "" },
    };
    if (name) {
      return result[name];
    } else {
      return result;
    }
  },

};
