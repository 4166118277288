"use strict";
import * as Vue from "vue";
import { RestUrl, Rest } from "./Rest";
import moment from "moment";
import base64decode from 'base-64';

const Code = {};

const Constants = {
  MAX_PRICE: 300000,
};

const Common = {
  staticUrl(path) {
    return `${RestUrl.KOOKMINPT_STATIC_URL}/${path}`;
  },
  moment(date) {
    return moment(date).format("YYYY.MM.DD HH:mm:ss");
  },
  momentDay(date) {
    return moment(date).format("YYYY.MM.DD");
  },
  momentDayDot(date) {
    return moment(date).format("YY.MM.DD");
  },

  toMoneyFormat(str, inx, fix) {
    if (typeof fix === "undefined") {
      fix = false;
    }
    if (!str || str == NaN || str == 0) {
      return 0;
    }
    let minus = "";
    let major = "";
    let minor = "";
    let target = "";
    let dot = "";

    str = Number(str).toFixed(10);

    if (str.charAt(0) == "-") {
      minus = "-";
      str = str.substring(1);
    }

    if (str.indexOf(".") > 0) {
      major = str.substring(0, str.indexOf("."));
      minor = str.substring(str.indexOf(".") + 1, str.length);
      dot = ".";
    } else {
      major = str;
    }

    if (major.length > 3) {
      for (var i = major.length; i > 0; i -= 3) {
        if (i - 3 > 0) {
          target = major.substring(i - 3, i) + target;
          target = "," + target;
        } else {
          target = major.substring(0, i) + target;
        }
      }
    } else {
      target = major;
    }

    // 뒤에 0을 제거 한다.
    if (minor.length > inx) {
      minor = minor.substring(0, inx);
    }
    if (!fix) {
      minor = minor.replace(/(0+$)/, "");
    }
    if (minor.length == 0) {
      dot = "";
    } else {
      dot = ".";
    }

    return minus + target + dot + minor;
  },

  comma(data) {
    return this.toMoneyFormat(data, 8)
  },
  commaUsd(data) {
    return Common.toMoneyFormat(data, 4)
  },
  comma2(data) {
    return Common.toMoneyFormat(data, 2)
  },
  statusList:{
    'R':'요청상태',
    'C':'완료',
    'P':'펜딩',
    'S':'진행중',
    'F':'실패'
  },
  renderStatus(status) {
    var text = '';
    if(Common.statusList[status] != undefined){
      text = Common.statusList[status];
    }
    return text
  },
  typeList:{
    'II':'내부 입금',
    'EI':'외부 입금',
    'IO':'내부 출금',
    'EO':'외부 출금',
    'TF':'수수료',
    'AR':'관리자 입금',
    'AB':'관리자 출금',
    'ZO':'구매 출금',
    'ZI':'판매 입금',
    'ZC':'구매취소(구매자입장), 판매취소(판매자입장)', //입금
    'SO':'교환 출금',
    'SI':'교환 입금'
  },
  renderType(type) {
    var text = '';
    if(Common.typeList[type] != undefined){
      text = Common.typeList[type];
    }
    return text
  },

  getPayload(token) {
    var vals = token.split('.');
    return JSON.parse(base64decode.decode(vals[1]));
  },

  getOperatingTime(close) {
    if (typeof close === "undefined") {
      close = false;
    }

    return Array.from({ length: 48 }).map((_, index) => {
      let hour =
        Math.floor(index / 2) > 24
          ? Math.floor(index / 2) - 24
          : Math.floor(index / 2);

      if (close == true) {
        if (!hour && !(index % 2)) {
          hour = 24;
        }
      }
      hour = hour < 10 ? "0" + hour : hour;
      return {
        label: `${hour}:${index % 2 ? "30" : "00"}`,
        value: `${hour}:${index % 2 ? "30" : "00"}`,
      };
    });
  },

  getRating(average) {
    var result = [];
    for (var i = 0; i < 5; i += 1) {
      if (average >= 0.75) {
        result.push("F");
      } else {
        result.push(average >= 0.25 ? "H" : "E");
      }
      average -= 1;
    }
    return result;
  },

  getGalleryImages(items) {
    var result = [];
    if (items.length > 0) {
      items.forEach((element) => {
        result.push({ src: element });
      });
    }
    return result;
  },

  serialize(obj) {
    let str = [];
    for (let p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  },

  serverImageURL(path, query) {
    const IsExistImageQuery = path.includes("?");

    if (IsExistImageQuery) {
      return path;
    }
    const newPath = path.includes("&")
      ? path.replace("&", "?")
      : `${path}${query}`;

    if (path.includes(RestUrl.KOOKMINPT_SERVER_IMAGE_URL)) {
      return newPath;
    }
    return `${RestUrl.KOOKMINPT_SERVER_IMAGE_URL}/${newPath}`;
  },

  serverImageRemoveDomain(path) {
    return path.replace(`${RestUrl.KOOKMINPT_SERVER_IMAGE_URL}/`, "");
  },

  windowScrollTop() {
    window.scrollTo(0, 0);
 
  },

  setClipboard(str,root){
    window.navigator.clipboard.writeText(str).then((e) => {
      root.$toast.success('Copy Complete')
    });
  },

  timeGap(old,root) {
    var old = moment(old);
    const duration = moment.duration(moment().diff(old));
    var diffTime = {
      // years: duration.years(),
      // months: duration.months(),
      days: duration.days(),
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds()
    };
    var result = '-';
    if ( diffTime.days > 0) {
      result = root.$t('t_57',{time:diffTime.days});
    } else if (diffTime.hours > 0) {
      result = root.$t('t_58',{time:diffTime.hours});
    } else if (diffTime.minutes > 0) {
      result = root.$t('t_59',{time:diffTime.minutes});
    } else if (diffTime.seconds > 1) {
      result = root.$t('t_60',{time:diffTime.seconds});
    } else if (diffTime.seconds >= 0) {
      result = 'new'
    }
    return result;
  },
  textEllipsis(str) {
    if (str.length > 30) {
      return str.substr(0, 10) + '...' + str.substr(str.length-10, str.length);
    }
    return str;
  }
};

export { Code, Common, Constants };
