<template>
  <template v-if="name == 'retry'">
    <svg
      v-if="sizeType == '16'"
      :class="'ic_' + name"
      :style="customStyle"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 8.33333C4 10.7266 5.9401 12.6667 8.33333 12.6667C10.7266 12.6667 12.6667 10.7266 12.6667 8.33333C12.6667 5.9401 10.7266 4 8.33333 4C7.79976 4 7.28871 4.09644 6.81667 4.27283"
        :stroke="color"
        :stroke-width="strokeWidth"
        stroke-linecap="square"
      />
      <path
        d="M8 2L6 4L8 6"
        :stroke="color"
        :stroke-width="strokeWidth"
        stroke-linecap="square"
      />
    </svg>
    <svg
      v-else
      :class="'ic_' + name"
      :style="customStyle"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 12.5C6 16.0899 8.91015 19 12.5 19C16.0899 19 19 16.0899 19 12.5C19 8.91015 16.0899 6 12.5 6C11.6996 6 10.9331 6.14465 10.225 6.40924"
        :stroke="color"
        :stroke-width="strokeWidth"
        stroke-linecap="square"
      />
      <path
        d="M12 3L9 6L12 9"
        :stroke="color"
        :stroke-width="strokeWidth"
        stroke-linecap="square"
      />
    </svg>
  </template>
</template>
<script lang="js">
export default {
  props: {
     name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#394150",
    },
    strokeWidth:{
        type: String,
      default: "2",
    }, secondColor: {
      type: String,
      default: "#E5E7EB",
    },
    sizeType:{
        type: String,
        default: '24',
    },
   customStyle:{default:{}}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  destroyed() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
