<template>
  <template v-if="name == 'arrowLeft'">
    <svg
      v-if="sizeType == '16'"
      :class="'ic_' + name"
      :style="customStyle"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.667 12.6666L5.33366 7.99996L10.667 3.33329"
        :stroke="color"
        :stroke-width="strokeWidth"
        stroke-linecap="square"
      />
    </svg>
    <svg
      v-else
      :class="'ic_' + name"
      :style="customStyle"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 19L8 12L16 5"
        :stroke="color"
        :stroke-width="strokeWidth"
        stroke-linecap="square"
      />
    </svg>
  </template>
</template>
<script lang="js">
export default {
  props: {
    name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#394150",
    },
    strokeWidth:{
        type: String,
      default: "2",
    },
    secondColor: {
      type: String,
      default: "#E5E7EB",
    },
    sizeType:{
        type: String,
        default: '24',
    },
  customStyle:{default:{}}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  destroyed() {},
};
</script>
<style scoped>
@media only screen and (min-width: 280px) {
}
</style>
