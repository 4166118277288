<template>
    <router-view v-if="routeLoading === true">
    </router-view>
    <div v-if="getIsLoading" class="isLoading">
      <svgIcon :name="'puff'" :customStyle="{width:'60px',height:'60px'}"/>
    </div>
</template>

<script>
import { RouterView } from "vue-router";
import Root from "./views/root";
import $ from "jquery";
import svgIcon from "./components/svg";
import { uuid } from "vue3-uuid";
export default {
    name: "App",
    extends: Root,
    components: { RouterView,svgIcon},
    data: function() {
        return {
            routeLoading: false,
        };
    },
    computed: {},
    async created() {
        var self = this;
        
        if(process.env.VUE_APP_ENV){
            this.setEnv(process.env.VUE_APP_ENV);
        }
        if (this._session.hasSession()) {
          this.getMeData(() => {
            this.routeLoading = true;
          });
        } else {
            this.routeLoading = true;
        }

        if (this._session.hasLang()) {
          this.$i18n.locale = this._session.getLang()
        } 

    },
    methods: {
        handleResize(event) {
            if (window.innerWidth >= 970) {
                if ($("body").hasClass("mobile")) {
                    $("body").removeClass("mobile");
                    this.setMobile(false);
                }
            } else {
                if (!$("body").hasClass("mobile")) {
                    $("body").addClass("mobile");
                    this.setMobile(true);
                }
            }
        },
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.handleResize);
    },
    mounted() {
        this.handleResize();
        window.addEventListener("resize", this.handleResize);
    },
};
</script>

<style scoped>
.isLoading {
    position: fixed;
    inset: 0px;
    z-index: 999;
    justify-content: center;
    align-items: center;
    display: flex;
    left: 0;top:0;right:0;bottom:0
}
</style>
